const Config = {
    APP_NAME: 'Space Trade',
    MOBILE_BREAK_POINT: 640,
    DATE_FORMAT: 'DD/MM/YYYY',
    TIME_FORMAT: 'HH:mm:ss',
    DATE_TIME_FORMAT: 'DD/MM/YYYY - HH:mm:ss',
    TENANT_ONE: 'tenant-1',
    TENANT_TWO: 'tenant-2',
} as const;

export default Config;

'use client';

import Link from 'next/link';
import React, { useState } from 'react';
import { cn } from '@/utils/utils';
import { TNavigationMenu } from './navigation-menu';
import Tooltip from '@/components/Tooltip/Tooltip';
import { usePathname } from 'next/navigation';
import { usePrefStore } from '@/store/prefStore';
import Typography from '@/components/ui/typography';
import { Button } from '@/components/ui/button';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { Separator } from '@/components/ui/separator';
import useEnv from '@/hooks/useEnv';

interface NavProps {
    withCollapseBtn?: boolean;
    links: TNavigationMenu[];
    className?: string;
}

const DEFAULT_OPEN_MENU = ['my_library', 'backtest', 'live'];
const PROJECT_ID = 'tenant-1';

export function Nav({ links, className, withCollapseBtn }: NavProps) {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const pathname = usePathname();
    const { projectId } = useEnv();

    const isChildSelected = (children: TNavigationMenu[]) => {
        const res = children.find(child => child.path === pathname);
        return !!res;
    };
    return (
        <div
            data-collapsed={isCollapsed}
            className={cn(
                'hide-scrollbar group z-50 flex h-full min-w-[60px] flex-col gap-4 overflow-y-scroll border-r bg-background py-2 transition-all duration-300 ease-in-out data-[collapsed=true]:py-2',
                isCollapsed ? 'w-[60px] items-center justify-center' : 'w-[200px]',
                className
            )}>
            <nav
                className={cn(
                    'fixed top-16 mt-4 grid gap-1 bg-background px-2 font-normal group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:space-y-3 group-[[data-collapsed=true]]:px-2',
                    isCollapsed ? 'w-[60px] items-center justify-center' : 'w-[180px]',
                    !withCollapseBtn && 'top-8'
                )}>
                <Accordion
                    defaultValue={projectId === PROJECT_ID && DEFAULT_OPEN_MENU}
                    type='multiple'
                    className={cn(
                        'flex w-full flex-col',
                        isCollapsed && 'items-center justify-center'
                    )}>
                    {links.map(props => {
                        const { id, type, icon: Icon, children, label, disabled } = props;
                        if (isCollapsed) {
                            if (type === 'link') {
                                return <IconLink key={id} {...props} />;
                            }

                            return (
                                <HoverCard key={id}>
                                    <HoverCardTrigger>
                                        <Button
                                            variant='ghost'
                                            size='icon'
                                            aria-disabled={disabled}
                                            disabled={disabled}>
                                            <Icon className='my-1 h-5 w-5 hover:opacity-70' />
                                        </Button>
                                    </HoverCardTrigger>
                                    {!disabled && (
                                        <HoverCardContent
                                            className='!z-50 ml-12 flex w-fit flex-col space-y-2'
                                            sideOffset={-5}>
                                            <Typography variant='bold'>{label}</Typography>
                                            <Separator />
                                            {children &&
                                                children?.length > 0 &&
                                                children?.map(props => {
                                                    const {
                                                        id,
                                                        path,
                                                        label: linkLabel,
                                                        tenant,
                                                    } = props;
                                                    if (
                                                        tenant &&
                                                        tenant !==
                                                            process.env.NEXT_PUBLIC_PROJECT_ID
                                                    )
                                                        return;

                                                    return (
                                                        <Link
                                                            key={id}
                                                            aria-label={label}
                                                            href={path}
                                                            className={cn(
                                                                'hover:opacity-70',
                                                                pathname === path &&
                                                                    'font-bold text-opacity-50 hover:text-primary',
                                                                pathname === path
                                                                    ? 'text-primary'
                                                                    : 'opacity-90'
                                                            )}>
                                                            {linkLabel}
                                                        </Link>
                                                    );
                                                })}
                                        </HoverCardContent>
                                    )}
                                </HoverCard>
                            );
                        } else {
                            if (type === 'group') {
                                return (
                                    <AccordionItem
                                        key={id}
                                        value={id}
                                        disabled={disabled}
                                        className='w-full border-none'>
                                        <AccordionTrigger
                                            className={cn(
                                                'w-full py-2 hover:no-underline hover:opacity-80'
                                            )}>
                                            <Button
                                                variant='ghost'
                                                disabled={disabled}
                                                className={cn(
                                                    'm-0 ml-4 flex h-fit items-center gap-x-1 p-0',
                                                    isChildSelected(children) &&
                                                        'space-x-2 font-bold text-primary hover:opacity-70 dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white'
                                                )}>
                                                <Icon className='h-5 w-5' />
                                                {label}
                                            </Button>
                                        </AccordionTrigger>
                                        <AccordionContent className='flex flex-col'>
                                            {children &&
                                                children?.length > 0 &&
                                                children.map(props => {
                                                    const { id } = props;
                                                    return <SingleLink key={id} {...props} />;
                                                })}
                                        </AccordionContent>
                                    </AccordionItem>
                                );
                            }
                            if (type === 'link') {
                                return (
                                    <div key={id} className='w-full border-none'>
                                        <SingleLink {...props} />
                                    </div>
                                );
                            }
                        }
                    })}
                </Accordion>
            </nav>
            {withCollapseBtn && (
                <Button
                    className={cn(
                        'fixed top-36 z-50 flex translate-x-[15px] transform items-center justify-center self-end rounded-full border bg-white shadow-md'
                    )}
                    variant='ghost'
                    size='icon'
                    aria-label='Toggle sidebar'
                    onClick={() => setIsCollapsed(prevState => !prevState)}>
                    {!isCollapsed ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
                </Button>
            )}
        </div>
    );
}

const SingleLink = (props: TNavigationMenu) => {
    const { path, icon: Icon, label, disabled, tenant } = props;
    const pathname = usePathname();
    const pref = usePrefStore();

    if (tenant && tenant !== process.env.NEXT_PUBLIC_PROJECT_ID) return;
    return (
        <Button
            variant='ghost'
            className='m-0 w-full justify-start p-0'
            aria-disabled={disabled}
            disabled={disabled}>
            <Link
                href={path}
                onClick={() => pref.setShowSidebar(false)}
                className={cn(
                    'my-1 ml-7',
                    pathname === path &&
                        'space-x-2 font-bold text-primary hover:opacity-70 dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white'
                )}>
                <div className='flex items-center justify-start gap-x-2 hover:opacity-70'>
                    <Icon className='h-4 w-4 opacity-90' />
                    <Typography variant='span' className={cn(pathname !== path && 'opacity-90')}>
                        {label}
                    </Typography>
                </div>
            </Link>
        </Button>
    );
};

const IconLink = (props: TNavigationMenu) => {
    const { path, icon: Icon, label, disabled, tenant } = props;
    const pathname = usePathname();
    if (tenant && tenant !== process.env.NEXT_PUBLIC_PROJECT_ID) return;

    return (
        <Button variant='ghost' size='icon' aria-disabled={disabled} disabled={disabled}>
            <Link
                aria-label={label}
                href={path}
                className={cn(
                    pathname.includes(path) && 'font-bold text-opacity-50 hover:text-primary',
                    pathname.includes(path) ? 'text-primary' : 'opacity-90'
                )}>
                <Tooltip
                    content={label}
                    side='right'
                    className='bg-primary-foreground bg-opacity-100 text-sm font-semibold text-secondary-foreground shadow-lg'>
                    <Icon className='my-1 h-5 w-5 hover:opacity-70' />
                </Tooltip>
            </Link>
        </Button>
    );
};
